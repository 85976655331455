import case1 from "@/assets/Case/case-1.jpg";
import case2 from "@/assets/Case/case-2.jpg";
import case3 from "@/assets/Case/case-3.jpg";
import case4 from "@/assets/Case/case-4.jpg";
import Service from '@/components/Service/service.vue'
import HomeVideo from '@/assets/Media/home-video.mp4'
import AboutVideo from '@/assets/Media/about-video.mp4'
import {mapMutations} from "vuex";


/**
 * Home
 */
const Home = {
    name:'Home',
    components:{Service },
    data() {
        return {
            HomeVideo:HomeVideo,
            AboutVideo:AboutVideo,
            visible:false,
            home:false,
            about:false,
            service:false,
            core:false,
            caseStatus:false,
            joinUs:false,
            uiStatus:false,
            nlpStatus:false,
            planStatus:false,
            ideaStatus:false,
            clerkStatus:false,
            options: {
                licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
                //是否显示导航，默认为false
                navigation: true,
                loopHorizontal:true,
                controlArrows: true,
                afterLoad: this.afterLoad,
                // anchors: ['home', 'about', 'service', 'core', 'case','joinUs','contact'],
                anchors: ['home', 'about', 'service', 'core','joinUs','contact'],
            },
            caseList:[
                {
                    links:case1,
                    title:'GEELY AUTO',
                    content:'EPR运营服务'
                },
                {
                    links:case2,
                    title:'某知名汽车品牌',
                    content:'EPR运营服务'
                },
                {
                    links:case3,
                    title:'吉利汽车用户运营',
                    content:'EPR运营服务'
                },
                {
                    links:case4,
                    title:'某知名汽车品牌',
                    content:'EPR运营服务'
                }
            ]
        }
    },
    created() {
        this.afterLoad()
    },
    methods:{
        ...mapMutations('service',['SET_SERVICE_STATUS']),
        ...mapMutations('core',['SET_STATUS']),
        ...mapMutations('header',['SET_POINT']),
        /**
         * 打开弹框
         * @param name
         */
        onOpen(name){
            switch (name){
                case 'uiStatus':
                    this.uiStatus = true;
                    break;
                case 'nlpStatus':
                    this.nlpStatus = true;
                    break;
                case 'planStatus':
                    this.planStatus = true;
                    break;
                case 'ideaStatus':
                    this.ideaStatus = true;
                    break;
                case 'clerkStatus':
                    this.clerkStatus = true;
                    break;
            }
        },
        /**
         * 关闭
         */
        onClose(){
            this.uiStatus = false;
            this.nlpStatus = false;
            this.planStatus = false;
            this.ideaStatus = false;
            this.clerkStatus = false;
        },
        /**
         * 跳转service 二级页面
         * @param number
         */
        onServiceLicks(number){
            this.SET_SERVICE_STATUS(number)
            this.$router.push('/service/content')
        },
        /**
         * 跳转core 二级页面
         * @param number
         */
        onCoreLicks(number){
            this.SET_STATUS(number)
            this.$router.push('/core/content')
        },
        /**
         * 跳转case 二级页面
         * @param number
         */
        onCaseLicks(number){
            this.SET_STATUS(number)
            this.$router.push('/case/content')
        },
        /**
         * 回调函数
         */
        afterLoad(){
            let path = window.location.hash;
            path === '' || path === '#home'? this.home = true : this.home = false
            path === '#about'? this.about = true : this.about = false
            path === '#service'? this.service = true : this.service = false
            path === '#core'? this.core = true : this.core = false
            path === '#case'? this.caseStatus = true : this.caseStatus = false
            path === '#joinUs'? this.joinUs = true : this.joinUs = false

            switch (path) {
                case '#home':
                    this.SET_POINT('home');
                    break;
                case '':
                    this.SET_POINT('home');
                    break;
                case '#about':
                    this.SET_POINT('about');
                    break;
                case '#service':
                    this.SET_POINT('service');
                    break;
                case '#core':
                    this.SET_POINT('core');
                    break;
                case '#case':
                    this.SET_POINT('case');
                    break;
                case '#joinUs':
                    this.SET_POINT('joinUs');
                    break;
                case '#contact':
                    this.SET_POINT('contact');
                    break;
            }
            this.onClose()

        }
    },
}
export default Home
