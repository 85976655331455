<template>
    <div class="layout-service">
        <div class="service-list">
            <div class="service-box a-fadeinR">
                <div class="service-top">
                    <span>01</span>
                    <h1>USER OPERATION</h1>
                </div>
                <div class="service-content"><img src="../../assets/Service/user-img.png"></div>
                <h1 class="service-title">全链路用户运营</h1>
                <p class="service-text">基于用户全生命周价值管理</p>
                <div class="service-line">
                    <svg version="1.1" id="图层_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="620" height="300" viewBox="46 40 956 400" style="enable-background:new 0 0 548.03 369.79;" xml:space="preserve">
                            <polyline class="st0" style="fill:none;stroke:#fff;stroke-width:0.5;stroke-miterlimit:10;" points="57.2,47.44 57.2,321.46 68.73,332.98 410.58,332.98 418.34,340.74 499.7,340.74 499.7,283.77 "/>
                        </svg>
                </div>
            </div>
            <div class="service-box a-fadeinR service1_2">
                <div class="service-top">
                    <span>02</span>
                    <h1>REPUTATION MANAGEMENT</h1>
                </div>
                <div class="service-content"><img src="../../assets/Service/reputation-img.png"></div>
                <h1 class="service-title">品牌声誉管理</h1>
                <p class="service-text">一站式品牌声誉管理</p>
                <div class="service-line">
                    <svg version="1.1" id="图层_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="620" height="300" viewBox="46 40 956 400" style="enable-background:new 0 0 548.03 369.79;" xml:space="preserve">
                                <polyline class="st0" style="fill:none;stroke:#fff;stroke-width:0.5;stroke-miterlimit:10;" points="57.2,47.44 57.2,321.46 68.73,332.98 410.58,332.98 418.34,340.74 499.7,340.74 499.7,283.77 "/>
                        </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Service from './service'
export default Service
</script>
<style lang="less">
@import "service";
</style>
