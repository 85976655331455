<template>
    <div class="layout-body">
        <full-page :options="options" id="fullpage" ref="fullpage" @moveTo="moveTo">
            <!--s: 第一屏幕-->
            <div class="section">
                <div class="layout-center">
                    <video :src='HomeVideo' data-autoplay class="layout-media" autoplay="autoplay" loop="loop" muted="muted"></video>
                    <div class="layout-content" v-if="home">
                        <h1 class="content-title a-fadeinB">以大数据和数字化技术为内核的全链路营销科技公司</h1>
                        <h2 class="content-info a-fadeinB page1_2">专注 专业 创新 诚信</h2>
                    </div>
                </div>
            </div>
            <!--e: 第一屏幕-->
            <!--s: 第二屏幕-->
            <div class="section">
                <div class="layout-center">
                    <video :src='AboutVideo' data-autoplay class="layout-media" autoplay="autoplay" loop="loop" muted="muted"></video>
                    <div class="layout-about" v-if="about">
                        <div class="about-logo">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 2267.72 2267.72" style="enable-background:new 0 0 2267.72 2267.72;" xml:space="preserve">
                                <g id="图层_2">
                                    <path class="a-bounceinB" fill="#fff" d="M898.01,1144.61v456.82c0,191.21-155.01,346.22-346.22,346.22h0v-558.19"/>
                                </g>
                                <g id="图层_5">
                                    <path class="a-bounceinT" fill="#fff" d="M551.79,1308.87V666.88c0-191.21,155.01-346.22,346.22-346.22h0v757.97"/>
                                </g>
                                <g id="图层_4">
                                    <path class="a-bounceinB" fill="#fff" d="M1871.18,582.7v1018.44c0,191.21-155.01,346.22-346.22,346.22h0V895.22"/>
                                </g>
                                <g id="图层_3">
                                    <g>
                                        <polygon class="a-flash" fill="#fff" stroke-width="3"  points="105.56,1606.24 1048.57,845.17 1213.56,1173.23 2162.15,320.07 1976.53,842.87 1940.67,714.54
                                          1146.1,1429.24 971.37,1029.98"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="about-content">
                            <h1 class="title a-fadeinR">HAOMING TECH</h1>
                            <h2 class="name a-fadeinR about_1">关于昊明数科
                                <svg  class="content-svg" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                    <line fill="none" stroke="#fff" stroke-width="4" stroke-linejoin="round" stroke-miterlimit="10" x1="4" y1="92" x2="68" y2="92"></line>
                                </svg>
                            </h2>
                            <p class="content a-fadeinR about_2">昊明数科成立于2016年，当下数字化技术正在加速营销传播深刻变革，在互联网流量红利殆尽，品牌传播赛道越发拥挤的当下，企业面临用户和品牌资产增长的多重困境，昊明以需创变，将传播艺术与数字化技术相融合为客户提供全链路传播和用户运营服务，帮助客户实现可持续良性增长！</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: 第二屏幕-->

            <!--s: 第三屏幕-->
            <div class="section">
                <div class="layout-service">
                    <div class="service-list" v-if="service">
                        <div class="service-left a-fadeinR">
                            <div class="box">
                                <h1 class="title">MARKETING<br/>SERVICES</h1>
                                <p class="text">营销服务</p>
                            </div>
                        </div>
                        <div class="service-box a-fadeinR service1_1" @click="onServiceLicks(1)">
                            <div class="service-top">
                                <span>01</span>
                                <h1>USER OPERATION</h1>
                            </div>
                            <div class="service-content"><img src="../../assets/Service/user-img.png"></div>
                            <h1 class="service-title">全链路用户运营</h1>
                            <p class="service-text">基于用户全生命周价值管理</p>
                            <div class="service-line">
                                <svg version="1.1" id="图层_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     width="620" height="300" viewBox="46 40 956 400" style="enable-background:new 0 0 548.03 369.79;" xml:space="preserve">
                                    <polyline class="st0" style="fill:none;stroke:#fff;stroke-width:0.5;stroke-miterlimit:10;" points="57.2,47.44 57.2,321.46 68.73,332.98 410.58,332.98 418.34,340.74 499.7,340.74 499.7,283.77 "/>
                                </svg>
                            </div>
                        </div>
                        <div class="service-box a-fadeinR service1_2" @click="onServiceLicks(2)">
                            <div class="service-top">
                                <span>02</span>
                                <h1>REPUTATION MANAGEMENT</h1>
                            </div>
                            <div class="service-content"><img src="../../assets/Service/reputation-img.png"></div>
                            <h1 class="service-title">品牌声誉管理</h1>
                            <p class="service-text">一站式品牌声誉管理</p>
                            <div class="service-line">
                                <svg version="1.1" id="图层_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     width="620" height="300" viewBox="46 40 956 400" style="enable-background:new 0 0 548.03 369.79;" xml:space="preserve">
                                      <polyline class="st0" style="fill:none;stroke:#fff;stroke-width:0.5;stroke-miterlimit:10;" points="57.2,47.44 57.2,321.46 68.73,332.98 410.58,332.98 418.34,340.74 499.7,340.74 499.7,283.77 "/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: 第三屏幕-->
            <!--s: 第四屏幕-->
            <div class="section">
                <div class="layout-core">
                    <div class="core-box" v-if="core">
                        <!--s: content-->
                        <div class="core-content">
                            <h1 class="title a-fadeinB">MARKETING<br/>SERVICES</h1>
                            <h2 class="content-t a-fadeinB core1_1">昊明数科核心技术产品</h2>
                            <p class="content a-fadeinB core1_2">
                                以社交媒体大数据洞察为核心，实现目标人群精准触达，提升社会化媒体传播获客效能<br/>
                                从账号定位到内容策划运营，一站式自媒体矩阵托管服务<br/>
                                依托数字化技术工具，帮助品牌构建私域护城河，实现持续留存和转化
                            </p>
                        </div>
                        <!--e: content-->
                        <!--s: list-->
                        <div class="core-list">
                            <ul>
                                <li class="a-flipinX" @click="onCoreLicks(1)"><img src="../../assets/Core/core-1.jpg"></li>
                                <li class="a-flipinX core1_2" @click="onCoreLicks(2)"><img src="../../assets/Core/core-2.jpg"></li>
                                <li class="a-flipinX core1_3" @click="onCoreLicks(3)"><img src="../../assets/Core/core-3.jpg"></li>
                            </ul>
                        </div>
                        <!--e: list-->
                    </div>
                </div>
            </div>
            <!--e: 第四屏幕-->
            <!--s: 第五屏幕-->
            <!--<div class="section">
                <div class="layout-case">
                    <div class="case-list" v-if="caseStatus">
                        <ul>
                            <li @click="onCaseLicks" class="a-bounceinB" :class="'case1_'+key" :key="key" v-for="(listObj,key) in caseList">
                                <img :src="listObj.links"/>
                                <h1>{{ listObj.title }}</h1>
                                <h2>{{ listObj.content }}</h2>
                                <i class="case-more"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>-->
            <!--e: 第五屏幕-->
            <!--s: 第六屏幕-->
            <div class="section">
                <div class="layout-join-us">
                    <div class="join-us" v-if="joinUs">
                        <div class="join-title">
                            <h1 class="title a-fadeinT join1_1">JOIN US</h1>
                            <h3 class="text a-fadeinT">昊明直通车</h3>
                        </div>
                        <div class="join-list">
                            <ul>
                                <li class="a-bounceinB" @click="onOpen('nlpStatus')">
                                    <div class="join-li"><img src="../../assets/JoinUs/join-img1.png"/></div>
                                    <div class="join-name">NLP工程师</div>
                                </li>
                                <li class="a-bounceinB join1_1" @click="onOpen('planStatus')">
                                    <div class="join-li"><img src="../../assets/JoinUs/join-img2.png"/></div>
                                    <div class="join-name">策略<br/>策划总监</div>
                                </li>
                                <li class="a-bounceinB join1_2" @click="onOpen('ideaStatus')">
                                    <div class="join-li"><img src="../../assets/JoinUs/join-img3.png"/></div>
                                    <div class="join-name">创意总监</div>
                                </li>
                                <li class="a-bounceinB join1_3" @click="onOpen('uiStatus')">
                                    <div class="join-li"><img src="../../assets/JoinUs/join-img4.png"/></div>
                                    <div class="join-name">美术指导<br/>资深设计</div>
                                </li>
                                <li class="a-bounceinB join1_4" @click="onOpen('clerkStatus')">
                                    <div class="join-li"><img src="../../assets/JoinUs/join-img5.png"/></div>
                                    <div class="join-name">文案策划</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: 第六屏幕-->
            <!--s: 第七屏幕-->
            <div class="section">
                <div class="layout-contact">
                    <div class="contact-box">
                        <div class="contact-con">
                            <img src="../../assets/Contact/contact-img.jpg">
                            <div class="location">
                                <span class="logo"></span>
                                <div class="point point-shadow point-40"></div>
                                <div class="point point-shadow point-60"></div>
                                <div class="point point-shadow point-80"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: 第七屏幕-->
        </full-page>
        <div class="join-box a-fadein" v-if="nlpStatus">
            <div class="layer" @click="onClose"></div>
            <div class="box">
                <a href="javascript:" class="box-close" @click="onClose"><img src="../../assets/close.png"/></a>
                <h1 class="title">NLP 工程师 <font>舆情分析、深度学习、AI撰稿</font></h1>
                <div class="content">
                    <h2 class="name">工作内容：</h2>
                    <div class="text">
                        1. 文本处理工作，包括情感分析、话题聚类、主题词提取、垃圾过滤等<br/>
                        2. 负责语料库的采集、整理、加工、维护优化等<br/>
                        3. 能化撰写新闻稿件
                    </div>
                </div>
                <div class="content">
                    <h2 class="name">工作要求：</h2>
                    <div class="text">
                        1.熟练使用python，深度学习基本功扎实，熟悉常见深度学习框架，如tensorflow、pytorch、lstm、bert等<br/>
                        2. 了解自然语言处理、数据挖掘、机器学习领域的基本原理<br/>
                        3.良好的数据分析能力，通过规则制定提升数据质量<br/>
                        4. 全日制本科学历及以上，计算机、信息相关专业，3年工作经验
                    </div>
                </div>
            </div>
        </div>
        <div class="join-box a-fadein" v-if="planStatus">
            <div class="layer" @click="onClose"></div>
            <div class="box">
                <a href="javascript:" class="box-close" @click="onClose"><img src="../../assets/close.png"/></a>
                <h1 class="title">策略／策划总监</h1>
                <div class="content">
                    <h2 class="name">工作内容：</h2>
                    <div class="text">
                        1. Social社会化营销根据公司发展战略和业绩目标制定团队业务计划和发展目标，带领团队达成；<br/>
                        2. 带领团队共同完成社群营销、粉丝互动营销、口碑营销策略方案，并负责项目的提案；<br/>
                        3. 洞察市场需求和趋势，正确理解并努力满足客户需求，进行有效的客户关系管理；深度挖掘、拓展新的生意机会；<br/>
                        4. 全面管理推进项目，对项目执行效果负最终责任； <br/>
                        5. 高效管理团队，搭建人才培养梯队。
                    </div>
                </div>
                <div class="content">
                    <h2 class="name">工作要求：</h2>
                    <div class="text">
                        1. 5年以上广告、公关或营销行业的客户服务经验或客户服务经验；<br/>
                        2. 具有丰富的社会化媒体及数字整合传播的项目经验，熟悉各大社交媒体、垂直论坛、电商平台机制；<br/>
                        3. 严谨的逻辑思维和优秀的沟通谈判能力；<br/>
                        4. 高效的项目管理能力；较强的客户关系建立和客户服务能力；<br/>
                        5. 具备团队培养意识和优秀的团队管理能力；<br/>
                        6. 具有汽车、金融、教育行业服务经验强
                    </div>
                </div>
            </div>
        </div>
        <div class="join-box a-fadein" v-if="ideaStatus">
            <div class="layer" @click="onClose"></div>
            <div class="box">
                <a href="javascript:" class="box-close" @click="onClose"><img src="../../assets/close.png"/></a>
                <h1 class="title">创意总监</h1>
                <div class="content">
                    <h2 class="name">工作内容：</h2>
                    <div class="text">
                        1、 根据品牌调性和客户需求，提出创意策略，带领团队成员实现优秀的创意产出；<br/>
                        2、能够清晰地向客户阐述和表达创意观点，能够策略性地说服引导客户；<br/>
                        3、保证并监督营销创意部门的创意产出的质量，帮助团队成员提升创意能力；<br/>
                        4、组织监督创意方案的完成，确保文字和视觉风格的构成符合策略及创意标准；<br/>
                        5、对客户进行创意提案，与客户做创意沟通。
                    </div>
                </div>
                <div class="content">
                    <h2 class="name">工作要求：</h2>
                    <div class="text">
                        1、5年以上广告、公关公司或知名互动公司工作经验；<br/>
                        2、丰富的创意团队管理经验，掌握传统创意，数字创意与内容创意的创意精髓，有自己独特的创意经验与方法；<br/>
                        3、思维敏捷、脑洞大，善于沟通，注重团队合作；<br/>
                        4、具备整合广告策略和创造能力，具有良好的提案和沟通能力；<br/>
                        5、保持对广告设计发展的敏感度，思维开拓，想法多元；<br/>
                        6. 能承受工作带来的较大压力，能适应加班等
                    </div>
                </div>
            </div>
        </div>
        <div class="join-box a-fadein" v-if="uiStatus">
            <div class="layer" @click="onClose"></div>
            <div class="box">
                <a href="javascript:" class="box-close" @click="onClose"><img src="../../assets/close.png"/></a>
                <h1 class="title">美术指导/资深设计</h1>
                <div class="content">
                    <h2 class="name">工作内容：</h2>
                    <div class="text">
                        1、独立高效完成主画面、提案延展、视觉海报、长图、插画、H5、UI等设计；，<br/>
                        2、能深刻解读和执行设计要求；<br/>
                        3、与客户进行良好的沟通，达成设计成果；<br/>
                        4、完成上级交办的其他工作。
                    </div>
                </div>
                <div class="content">
                    <h2 class="name">工作要求：</h2>
                    <div class="text">
                        1.本科以上学历，设计类相关专业毕业，具有2年以上创意广告、设计公司或公关公司从业经验；<br/>
                        2.熟练使用设计软件，具备较高的创意能力，实际设计执行水平高；<br/>
                        3.具备较好的审美品位，善于沟通、执行力强；<br/>
                        4.良好的美术功底，具有一定的插画和手绘能力；<br/>
                        5.有数字营销、汽车广告设计、social相关经验优先考虑。
                    </div>
                </div>
            </div>
        </div>
        <div class="join-box a-fadein" v-if="clerkStatus">
            <div class="layer" @click="onClose"></div>
            <div class="box">
                <a href="javascript:" class="box-close" @click="onClose"><img src="../../assets/close.png"/></a>
                <h1 class="title">文案策划</h1>
                <div class="content">
                    <h2 class="name">工作内容：</h2>
                    <div class="text">
                        1、深入理解洞察用户需求，撰写贴合目标用户需求的选题内容；<br/>
                        2、对汽车有一定程度的了解，能够独立撰写驾驭多种文字风格内容、微博、微信论坛等新媒体稿件，可独立撰写短视频脚本；<br/>
                        3、爱分享，文字功底强，能够协助社群运用做好内容分发及用户维护；<br/>
                        4、脑洞大，想法多；能产出on brief且优质的创意；<br/>
                        5、有网感，敏感度高，善于观察和思考；<br/>
                        6、有良好的文字功底和文案撰写能力；<br/>
                        7、善于与人沟通，善于团队协作，抗压能力强；
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Home from './home'
export default Home
</script>
<style lang="less">
@import "home";
</style>
