/**
 * Service
 */
const Service = {
    name:'Service',
    data() {
        return {
        }
    },
    methods: {
    }
}
export default Service
